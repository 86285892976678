import { styled } from '@linaria/react';
import { SIZE, TEXT } from 'theme/vars';

export const Wrapper = styled.div`
  width: var(${SIZE.CONTENT_WIDTH});
  margin: 0 auto;
  height: calc(100vh - var(${SIZE.HEADER_HEIGHT}));
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: var(${SIZE.HEADER_HEIGHT});
`;

export const Content = styled.div``;

export const Title = styled.h1`
  ${TEXT.TITLE_1}

  font-size: 96px;
  margin-bottom: 40px;
`;

export const Description = styled.p`
  ${TEXT.TITLE_2}

  font-size: 72px;
  margin-bottom: 40px;
`;
