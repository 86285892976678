import * as React from 'react';

import { Wrapper } from './styled';

type IconButtonProps = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLButtonElement>;

function IconButton({ children, ...props }: IconButtonProps) {
  return <Wrapper {...props}>{children}</Wrapper>;
}

export default IconButton;
