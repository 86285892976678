import { styled } from '@linaria/react';

import { COLOR, TEXT } from 'theme/vars';

import { TAG_VARIANT } from './constants';

export const Wrapper = styled.div`
  ${TEXT.CAPTION_BOLD}

  padding: 2px 10px;

  &[data-variant='${TAG_VARIANT.DARK}'] {
    background-color: var(${COLOR.NEUTRAL_900});
    color: var(${COLOR.WHITE});
  }

  &[data-variant='${TAG_VARIANT.LIGHT}'] {
    background-color: var(${COLOR.WHITE});
    color: var(${COLOR.NEUTRAL_900});
  }
`;
