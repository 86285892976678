import { styled } from '@linaria/react';

import { TRANSITION, TEXT, COLOR } from 'theme/vars';

export const Wrapper = styled.button`
  padding: 8px 16px;
  text-align: center;

  transition: var(${TRANSITION.DEFAULT});
  transition-property: background-color;

  cursor: pointer;

  background-color: var(${COLOR.ORANGE_NORMAL});

  color: var(${COLOR.NEUTRAL_900});

  ${TEXT.BUTTON}

  &:hover {
    background-color: var(${COLOR.ORANGE_DARK});
  }

  &:active {
    background-color: var(${COLOR.ORANGE_DARKER});
    transition: none;
  }
`;
