import * as React from 'react';

import { $Values } from 'utility-types';

import { Wrapper } from './styled';

import { TAG_VARIANT } from './constants';

type TagProps = {
  children: React.ReactNode;

  variant?: $Values<typeof TAG_VARIANT>;

  className?: string;
};

function Tag({ children, variant = TAG_VARIANT.DARK, className }: TagProps) {
  return (
    <Wrapper className={className} data-variant={variant}>
      {children}
    </Wrapper>
  );
}

export default Tag;
