import * as React from 'react';

import { Wrapper } from './styled';

type ButtonProps = {
  className?: string;

  onClick?: React.MouseEventHandler;

  children: React.ReactNode;
};

function Button({ className, onClick, children }: ButtonProps) {
  return (
    <Wrapper className={className} onClick={onClick} type='button'>
      {children}
    </Wrapper>
  );
}

export default Button;
