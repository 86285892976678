import { styled } from '@linaria/react';
import { COLOR, TEXT, TRANSITION } from 'theme/vars';

export const Wrapper = styled.button`
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;

  ${TEXT.BODY_DEFAULT}

  svg {
    display: block;

    fill: currentColor;

    margin-left: 5px;

    height: 30px;
    width: 30px;
  }

  transition: var(${TRANSITION.DEFAULT});
  transition-property: color;

  color: var(${COLOR.NEUTRAL_500});

  &:hover {
    color: var(${COLOR.ORANGE_NORMAL});
  }

  &:active {
    color: var(${COLOR.ORANGE_DARK});
    transition: none;
  }
`;
